/* App.css */

.App {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 50px;
}

h1 {
  font-size: 2rem;
  margin-bottom: 20px;
  color: #333;
}

.form-container {
  display: flex;
  justify-content: space-between;
  width: 80%; /* Adjust width as needed */
  margin-bottom: 20px;
}

.textarea-container {
  width: calc(50% - 10px); /* Adjust as needed */
}

.textarea-container h2 {
  font-size: 1rem; /* Adjust the font size as needed */
  margin-bottom: 10px; /* Add spacing below the title */
}

.textarea-container:first-child {
  margin-bottom: 20px; /* Add spacing between the top textarea and the title */
}

.textarea-container:first-child textarea {
  width: calc(100% - 20px); /* Adjust as needed */
  margin-right: 20px; /* Add spacing between text areas */
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: #fff; /* White background for input textarea */
  color: #333;
  font-family: Arial, sans-serif; /* Apply Arial font to textarea */
}

.textarea-container:last-child textarea {
  width: calc(100% - 20px); /* Adjust as needed */
  border: none; /* No border for result textarea */
  border-radius: 8px;
  background-color: #f2f2f2; /* Light gray background for result textarea */
  color: #333;
  font-family: Arial, sans-serif; /* Apply Arial font to textarea */
}

textarea {
  height: 200px;
  padding: 10px;
  font-size: 1rem;
  resize: none;
}

textarea:focus {
  outline: none;
  border-color: #007bff;
}

select, button {
  padding: 8px 12px;
  font-size: 1rem;
  border: none;
  border-radius: 4px;
  background-color: #007bff;
  color: #fff;
  cursor: pointer;
}

select {
  margin-right: 10px;
}

.translate-button {
  margin-top: 10px;
}

.translate-button:disabled {
  background-color: #ccc; /* Change background color to gray */
  color: #999; /* Change text color to lighter gray */
  border-color: #ccc; /* Change border color to match background */
  cursor: not-allowed; /* Change cursor to not-allowed */
  opacity: 0.7; /* Reduce opacity to indicate disabled state */
}

@media (hover: hover) {
  .translate-button:hover {
    background-color: #0056b3;
  }
}

.loading {
  margin-top: 20px;
  font-style: italic;
  color: #777;
}

.left-textarea-top {
  display: flex;
  position: relative;
}

.icon {
  width: 24px; /* Set width of the icon */
  height: 24px; /* Set height of the icon */
}

.icon:hover {
  opacity: 0.8; /* Reduce opacity on hover for better interaction */
}

/* Add media query for screens smaller than 768px (standard tablet and mobile screens) */
@media screen and (max-width: 768px) {
  .form-container {
    flex-direction: column; /* Stack form elements vertically on smaller screens */
  }

  .textarea-container {
    width: 100%; /* Make text areas full width on smaller screens */
    margin-bottom: 20px; /* Add some spacing between text areas */
  }

  textarea {
    width: 100%; /* Make text areas full width on smaller screens */
    height: 100px;
  }

  button {
    width: 40%; /* Make buttons full width on smaller screens */
  }

  h2 {
    font-size: 1.2rem; /* Increase font size for subtitle on smaller screens */
    text-align: center; /* Center-align subtitle on smaller screens */
    margin: 30px;
  }

  .button-container {
    display: flex;
    justify-content: center; /* Center-align the button horizontally */
    margin-top: 20px; /* Add margin space to the top of the button container */
  }

  .textarea-container {
    width: 100%; /* Make text areas full width on smaller screens */
    margin-bottom: 20px; /* Add some spacing between text areas */
  }

  button.translate-button {
    width: 35%; /* Set button width to 50% */
    margin-top: 30px;
  }

  .output-textarea {
    margin-bottom: 60px;
  }
}

.change-origin-button {
  width: 35px; /* Set fixed width and height to create a circular button */
  height: 35px;
  position: absolute;
  right: 0px;
  margin-right: 5px;
  border-radius: 50%; /* Make the button circular */
  background-color: #fff; /* White background */
  color: #ccc; /* Gray text color */
  cursor: pointer;
  margin: 15px; /* Add margin to all sides */
  margin-right: 5px;
  border: 2px solid #ccc; /* Add gray border */
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Apply hover effect only for non-touch devices */
@media (hover: hover) {
  .change-origin-button:hover {
    border-color: #565454; /* Darker gray border color on hover */
    background-color: #efefef;
  }
}


